/* Login */

#logo-form {
  margin-bottom: 30px;
  max-width: 200px;
}

.withI18nextTranslation\(Login\)-paper-1 {
  margin-top: 50px !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #057cf3 !important;
}

.MuiTypography-colorPrimary {
  color: #007bff !important;
}

a:hover {
  color: #ff5500 !important;
}

.MuiButton-containedPrimary {
  background-color: #057cf3 !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #3f414a !important;
}

/* Register */
.MuiInput-underline::after {
  border-color: #ff5500 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #ff5500 !important;
}

.main-regContainer {
  background: url('/static/login-bg.jpg');
  background-size: cover;
  background-repeat:no-repeat;
  min-height: 100vh;
  position: relative;
}

.form-container {
  background: #ffffffe6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
