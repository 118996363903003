header.MuiAppBar-colorPrimary {
    background: rgb(68,192,255);
    background: -moz-linear-gradient(90deg, rgba(68,192,255,1) 0%, rgba(2,131,190,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(68,192,255,1) 0%, rgba(2,131,190,1) 100%);
    background: linear-gradient(90deg, rgba(68,192,255,1) 0%, rgba(2,131,190,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#44c0ff",endColorstr="#0283be",GradientType=1);    
}
    
header img {
    max-width: 130px;
}

body {
    font-family: 'Roboto', sans-serif;
}

.action-btn {
    padding: 0px 0px 0px 0px !important;
    text-transform: none !important;
    color: linear-gradient(90deg, rgba(68,192,255,1) 0%, rgba(2,131,190,1) 100%);
}

.action-btn:hover {
    background: none !important;
}

.last-col {
    display: flex !important;
    flex-direction: row;
    justify-content: space-around;
}
